import { FC, useState } from 'react';
import { Datagrid, TextField, NumberField, BooleanField } from 'react-admin';
import TransactionStatusField from './TransactionStatusField';
import CustomDateField from '../../../shared/components/CustomDateField';
import VoidTransactionButton from './VoidTransactionButton';
import UnfreezeTransactionButton from './UnfreezeTransactionButton';
import { ButtonField } from '../../../shared/components/ButtonField';
import { JsonCodeBlock } from './JsonBlock';
import { Button, Dialog } from '@mui/material';
import { getSilaTransaction } from '../../../http';
import {UserShow} from "../../Users/components/UserShow";

export const TransactionsBankDatagrid: FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [record, setRecord] = useState<any | null>(null);

  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <JsonCodeBlock code={JSON.stringify(record, null, 4)} />
        <Button
          variant="contained"
          style={{ margin: '0 1rem 1rem 1rem' }}
          onClick={() => setOpenDialog(false)}
        >
          CLOSE
        </Button>
      </Dialog>
      <Datagrid rowClick="expand" expand={<UserShow />}>
        <TextField source="prettyId" label="ID" />
        <TextField source="currencyPair" />
        <NumberField
          source="amountCurrency1"
          label="Cash"
          options={{ maximumFractionDigits: 2 }}
        />
        <NumberField
          source="fee"
          label="Fee"
          options={{ maximumFractionDigits: 2 }}
        />
        <BooleanField source="feeCharged" />
        <TransactionStatusField source="status" />
        <TextField source="sourceAddress" />
        <TextField source="referenceId" />
        <CustomDateField source="nextRequestTime" format="LLL" />
        <CustomDateField source="createdAt" format="LLL" />
        <TextField source="id" label="Full ID" />
        <VoidTransactionButton />
        <UnfreezeTransactionButton />
        <ButtonField<any>
          label="Transaction"
          buttonLabel="ST"
          shouldRender={(r) => r?.referenceId && r?.user?.id}
          onClick={async (r) => {
            await getSilaTransaction(r.referenceId, r.user.id)
              .then((r) => setRecord(r))
              .catch((e) => setRecord(e));
            setOpenDialog(true);
          }}
        />
        <ButtonField<any>
          label="Record"
          buttonLabel="FR"
          onClick={(r) => {
            setOpenDialog(true);
            setRecord(r);
          }}
        />
      </Datagrid>
    </>
  );
};
