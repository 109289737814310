import { BooleanField, Datagrid, EditButton, EmailField, List, TextField, ExportButton } from "react-admin/esm";
import VerificationStatusField from "./VerificationStatusField";
import CustomDateField from "../../../shared/components/CustomDateField";
import { UserFilter } from "./UserFilter";
import UserInfoWrapper from './UserInfoWrapper';
import { UserShow } from "./UserShow";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
});

export const UserList = (props) => {
    const classes = useStyles();

    return (
        <List
            filters={<UserFilter />}
            filterDefaultValues={{ manualReview: '0' }}
            {...props}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
            actions={<ExportButton maxResults="50000" />}
        >
            <Datagrid rowClick="expand" expand={<UserShow />} className={classes.table}>
                <TextField source="fullName" />
                <EmailField source="email" className={classes.email} />
                <UserInfoWrapper source="phoneNumber" as={TextField} />
                <BooleanField source="verifiedEmail" />
                <VerificationStatusField source="verificationStatus" />
                <CustomDateField source="createdAt" format="LLL" />
                <BooleanField source="blocked" />
                <BooleanField source="deleted" />
                <EditButton className={classes.email} />
            </Datagrid>
        </List>
    );
};