import React from 'react';
import { List, Datagrid, TextField, TextInput, Filter, DateInput, ExportButton } from 'react-admin';
import CustomDateField from '../../shared/components/CustomDateField';
import { UserShow } from "../Users/components/UserShow";

export const VisitList = (props) => (
  <List
    {...props}
    filters={<VisitsFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false}
    actions={<ExportButton maxResults="50000" />}
  >
    <Datagrid rowClick="expand" expand={<UserShow />}>
      <TextField source="id" />
      <TextField source="user.email" />
      <TextField source="ip" />
      <TextField source="device" label="Agent" />
      <CustomDateField source="createdAt" format="LLLL" />
    </Datagrid>
  </List>
);

const VisitsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by user e-mail" source="email" alwaysOn />

    <DateInput
      label="Start Date"
      source="from"
      alwaysOn
    />
    <DateInput
      label="End Date"
      source="to"
      alwaysOn
    />
  </Filter>
);
