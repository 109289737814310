import type { FC } from 'react';
import UserIcon from '@material-ui/icons/Group';
import TransactionIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import VisitsIcon from '@material-ui/icons/VerticalSplit';
import BitcoinIcon from '@material-ui/icons/FormatBold';
import LogsIcon from '@material-ui/icons/ListAlt';
import WarningIcon from '@material-ui/icons/Warning';
import { Admin, Resource, fetchUtils } from 'react-admin';
import springbootDataProvider from './providers/DataProviders/ra-data-springboot-rest';
import { TransactionList } from './features/Transactions/Transactions';
import { VisitList } from './features/Visits/Visits';
import {
  OnfidoWarningsList,
  OnfidoWarningEdit,
} from './features/OnflidoWarnings/OnfidoWarnings';
import Dashboard from './features/Dashboard/Dashboard';
import authProvider from './providers/AuthProviders/JwtAuthProvider';
import { LogList } from './features/Logs/Logs';
import ActionsIcon from '@material-ui/icons/RemoveRedEye';
import LoginPage from './features/Login/LoginPage';
import { Route } from 'react-router-dom';
import settings from './features/Settings';
import { ActionsList } from "./features/Actions/Actions";
import LogoutButton from "./features/Login/LogoutButton";
import { UserList } from "./features/Users/components/UserList";
import { UserEdit } from "./features/Users/components/UserEdit";

export const App: FC = () => {

  const httpClient = (url, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = springbootDataProvider('/admin', httpClient);

  return (
    <Admin
      loginPage={LoginPage}
      logoutButton={LogoutButton}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      customRoutes={[
        <Route key="settings" path="/settings" component={settings.edit} />,
      ]}
    >
      <Resource name="users" list={UserList} edit={UserEdit} icon={UserIcon} />
      <Resource
        options={{ label: 'Crypto Transactions', type: 'CRYPTO' }}
        name="transactions/crypto"
        list={TransactionList}
        icon={BitcoinIcon}
      />
      <Resource
        options={{ label: 'GD Transactions', type: 'GD' }}
        name="transactions/gd"
        list={TransactionList}
        icon={TransactionIcon}
      />
      <Resource
        options={{ label: 'External Transactions', type: 'EXT' }}
        name="transactions/ext"
        list={TransactionList}
        icon={CreditCardIcon}
      />
      <Resource
        options={{ label: 'Onfido' }}
        name="onfido/warning"
        list={OnfidoWarningsList}
        edit={OnfidoWarningEdit}
        icon={WarningIcon}
      />
      <Resource name="visits" list={VisitList} icon={VisitsIcon} />
      <Resource name="actions" list={ActionsList} icon={ActionsIcon} />
      <Resource name="logs" list={LogList} icon={LogsIcon} />
      <Resource name="settings" list={LogList} icon={LogsIcon} />
    </Admin>
  );
};