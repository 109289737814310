import React from 'react';
import {
  Filter,
  Datagrid,
  List,
  SelectInput,
  TextField,
  SimpleForm,
  EditButton,
  Edit,
} from 'react-admin';

export const OnfidoWarningsList = (props) => (
  <List
    {...props}
    sort={{ field: 'code', order: 'ASC' }}
    perPage={25}
    filters={<OnfidoWarningsFilter />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="code" />
      <TextField source="reportField" />
      <TextField source="message" />
      <TextField source="elements" />
      <TextField source="category" />
      <EditButton />
    </Datagrid>
  </List>
);

const OnfidoWarningsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="category"
      label="Category Filter"
      emptyText="All categories"
      choices={[
        { id: 'APPROVE', name: 'APPROVE' },
        { id: 'DECLINE', name: 'DECLINE' },
        { id: 'WARNING', name: 'WARNING' },
      ]}
    />
  </Filter>
);

export const OnfidoWarningEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="code" />
      <TextField source="reportField" />
      <TextField source="message" />
      <TextField source="elements" />
      <SelectInput
        source="category"
        label="Category Filter"
        allowEmpty={false}
        choices={[
          { id: 'APPROVE', name: 'APPROVE' },
          { id: 'DECLINE', name: 'DECLINE' },
          { id: 'WARNING', name: 'WARNING' },
        ]}
      />
    </SimpleForm>
  </Edit>
);
