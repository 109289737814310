import { Filter, SelectInput, TextInput, DateInput } from "react-admin/esm";

export const UserFilter = (props) => {

  return (
    <Filter {...props}>
      <TextInput label="E-mail..." source="email" alwaysOn />
      <TextInput label="Name..." source="name" alwaysOn />
      <TextInput label="Phone number..." source="phone" alwaysOn />

      <SelectInput
        source="status"
        label="Status"
        choices={[
          { id: '-2', name: 'MANUAL REVIEW' },
          { id: '-1', name: 'DECLINED' },
          { id: '0', name: 'NOT VERIFIED' },
          { id: '1', name: 'VERIFIED' },
        ]}
        alwaysOn
      />
      <SelectInput
        source="isAdmin"
        label="Admin"
        choices={[
          { id: '0', name: 'BASIC USERS' },
          { id: '1', name: 'ADMINS' },
          { id: '2', name: 'ALL USERS' },
        ]}
        alwaysOn
      />

      <DateInput
        label="Start Date"
        source="from"
        alwaysOn
      />
      <DateInput
        label="End Date"
        source="to"
        alwaysOn
      />
    </Filter>
  );
}
