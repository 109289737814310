import React from 'react';
import { List, Datagrid, TextField, TextInput, Filter, DateInput, ExportButton } from 'react-admin';
import CustomDateField from '../../shared/components/CustomDateField';

export const ActionsList = (props) => (
  <List
    {...props}
    filters={<ActionsFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false}
    actions={<ExportButton maxResults="50000" />}
  >
    <Datagrid>
      <TextField source="user.email" label="Email" />
      <TextField source="description" />
      <TextField source="device" label="Agent" />
      <TextField source="page" />
      <TextField source="ip" />
      <CustomDateField source="createdAt" format="LLLL" />
    </Datagrid>
  </List>
);

const ActionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by user e-mail" source="email" alwaysOn />
    <TextInput label="Search by page" source="pageName" alwaysOn />
    <DateInput
      label="Start Date"
      source="from"
      alwaysOn
    />
    <DateInput
      label="End Date"
      source="to"
      alwaysOn
    />
  </Filter>
);
