import {SaveButton, Toolbar} from "react-admin";
import ApproveUserButton from "./ApproveUserButton";
import DeclineUserButton from "./DeclineUserButton";
import DeclineAchButton from "./DeclineAchButton";
import ResetPinButton from "./ResetPinButton";
import DeleteAccountButton from "./DeleteAccountButton";
import ReSubmitUserButton from "./ReSubmitUserButton";
import {Typography} from "@mui/material";

export const UserEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
        <Typography
            type="title"
            color="inherit"
            style={{ borderRight: '0.1em solid black', paddingLeft: '1em' }}
        />
        <SaveButton redirect={false} label="Update" />
        <Typography
            type="title"
            color="inherit"
            style={{ borderRight: '0.1em solid black', padding: '0.5em' }}
        />
        <ApproveUserButton {...props} />
        <Typography
            type="title"
            color="inherit"
            style={{ borderRight: '0.1em solid black', padding: '0.5em' }}
        />
        <DeclineUserButton {...props} />
        <Typography
            type="title"
            color="inherit"
            style={{ borderRight: '0.1em solid black', padding: '0.5em' }}
        />
        <DeleteAccountButton {...props} />
        <Typography
            type="title"
            color="inherit"
            style={{ borderRight: '0.1em solid black', padding: '0.5em' }}
        />
        <ReSubmitUserButton {...props} />
    </Toolbar>
);
